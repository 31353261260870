import React from 'react';
import { Typography, Link } from '@mui/material'

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Все права защищены © '}
      <Link color="inherit" href="https://retair.ru/">
          retair.ru
      </Link>
    </Typography>
  );
};

export default Copyright
